<template>
    <div>
        <Navbar />
        <Header :category="'visitors'" :bannerTitle="bannerTitle" :breadCrumb="borrowSpaceCrumb" />
        <ExhibitionBody />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import ExhibitionBody from  '../Organizers/Exhibition/ExhibitionBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'
    export default {
        name: 'ActivityDate',
        metaInfo() {
            return {
                title: this.$t("navbar.menu_1_1"),
            }
        },
        components: {
            Navbar,
            Header,
            ExhibitionBody,
            FooterTop,
            FooterBottom
        },
        data() {
            return {
                bannerTitle: "ACTIVITY<br>EXTENSION",
                borrowSpaceCrumb: [{
                    name : this.$t('navbar.menu_1')
                }]
            }
        }
    }
</script>

<style>
    .cover-image {
        margin-bottom: 50px;
        width: 100%;
        height: 400px;
    }

    .in-btn {
        margin-left: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .custom-select:focus {
        box-shadow: none !important;
        border: 1px solid #ced4da;
    }
</style>