var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar"),
      _c("Header", {
        attrs: {
          category: "visitors",
          bannerTitle: _vm.bannerTitle,
          breadCrumb: _vm.borrowSpaceCrumb
        }
      }),
      _c("ExhibitionBody"),
      _c("FooterTop"),
      _c("FooterBottom")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }